@use "fonts" as *;
@use "sizes" as *;
@use "node_modules/flag-icons/sass/flag-icons.scss" as *;
@use "themes" as *;

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-expansion-panel-content {
  .mat-expansion-panel-body {
    padding: 0 0 16px;
    .mat-mdc-list-item {
      padding-left: 24px;
    }
  }
}

.mdc-text-field--filled:not(.mdc-text-field--invalid) .mdc-line-ripple::before {
  border-bottom: none;
}

fieldset {
  border: none;
}

.w-100 {
  width: 100%;
}

.no-hint-input {
  .mat-mdc-form-field-bottom-align::before {
    display: none !important;
  }
}

.text-right {
  text-align: right !important;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 999;
}

.cursor-pointer {
  cursor: pointer;
}

.table-container {
  overflow-x: auto;
}

.text-nowrap {
  white-space: nowrap;
}

ngx-charts-legend {
  display: block !important;
}

.mdc-floating-label--required::after {
  color: red;
}

.mat-drawer-backdrop.mat-drawer-shown {
  display: none !important;
}

app-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
}
