@use '@angular/material' as mat;

@include mat.core();

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$violet-palette,
  ),
  typography: (
    brand-family: 'Roboto'
  ),
  density: (
    scale: -1
  )
));

$dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: mat.$violet-palette,
  ),
  typography: (
    brand-family: 'Roboto'
  ),
  density: (
    scale: -1
  )
));

html {
  @include mat.all-component-themes($light-theme);

  .error-button {
    @include mat.button-color($light-theme, $color-variant: error);
  }
}

body.dark-theme {
  @include mat.all-component-colors($dark-theme);

  .error-button {
    @include mat.button-color($dark-theme, $color-variant: error);
  }

  // Backgrounds
  $color-bg-darkest: #13141b;
  $color-bg-darker: #1111;
  $color-bg-dark: #232837;
  $color-bg-med: #2f3646;
  $color-bg-light: #455066;
  $color-bg-lighter: #5b6882;

  // Text
  $color-text-dark: #72809b;
  $color-text-med-dark: #919db5;
  $color-text-med: #A0AABE;
  $color-text-med-light: #d9dce1;
  $color-text-light: #f0f1f6;
  $color-text-lighter: #fff;

  background-color: $color-bg-darkest;

  .social-btn{
    border-color: $color-bg-light;
    background-color: $color-bg-light;
    color: $color-text-light;
  }

  .social-btn:hover {
    border-color: $color-bg-lighter;
    background-color: $color-bg-lighter;
  }

  .form-container__text{
    color: $color-text-light;
  }

  .ngx-charts {

    text {
      fill: $color-text-med;
    }

    .tooltip-anchor {
      fill: rgb(255, 255, 255);
    }

    .gridline-path {
      stroke: $color-bg-med;
    }

    .refline-path {
      stroke: $color-bg-light;
    }

    .reference-area {
      fill: #fff;
    }

    .grid-panel {
      &.odd {
        rect {
          fill: rgba(255, 255, 255, 0.05);
        }
      }
    }

    .force-directed-graph {
      .edge {
        stroke: $color-bg-light;
      }
    }

    .number-card {
      p {
        color: $color-text-light;
      }
    }

    .gauge {
      .background-arc {
        path {
          fill: $color-bg-med;
        }
      }

      .gauge-tick {
        path {
          stroke: $color-text-med;
        }

        text {
          fill: $color-text-med;
        }
      }
    }

    .linear-gauge {
      .background-bar {
        path {
          fill: $color-bg-med;
        }
      }

      .units {
        fill: $color-text-dark;
      }
    }

    .timeline {
      .brush-background {
        fill: rgba(255, 255, 255, 0.05);
      }

      .brush {
        .selection {
          fill: rgba(255, 255, 255, 0.1);
          stroke: #aaa;
        }
      }
    }

    .polar-chart .polar-chart-background {
      fill: rgb(30, 34, 46);
    }

  }

  .chart-legend {
    .legend-labels {
      background: rgba(255, 255, 255, 0.05) !important;
    }

    .legend-item {
      &:hover {
        color: #fff;
      }
    }

    .legend-label {
      &:hover {
        color: #fff !important;
      }

      .active {
        .legend-label-text {
          color: #fff !important;
        }
      }
    }

    .scale-legend-label {
      color: $color-text-med;
    }
  }

  .advanced-pie-legend {
    color: $color-text-med;

    .legend-item {
      &:hover {
        color: #fff !important;
      }
    }
  }

  .number-card .number-card-label {
    font-size: 0.8em;
    color: $color-text-med;
  }
}
