.f-150 {
  font-size: 150px;
  line-height: 160px;
}

.f-32 {
  line-height: 40px;
  font-size: 32px;
}

@media screen and (max-width: 992px) {
  .f-150 {
    font-size: 120px;
    line-height: 130px;
  }

  .f-32 {
    font-size: 28px;
    line-height: 36px;
  }
}
@media screen and (max-width: 768px) {
  .f-150 {
    font-size: 100px;
    line-height: 110px;
  }

  .f-32 {
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen and (max-width: 600px) {
  .f-150 {
    font-size: 70px;
    line-height: 80px;
  }

  .f-32 {
    font-size: 22px;
    line-height: 24px;
  }
}
@media screen and (max-width: 480px) {
  .f-150 {
    font-size: 50px;
    line-height: 60px;
  }

  .f-32 {
    font-size: 20px;
  }
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}
